function nav () {
    const burgerBtn = document.querySelector('.header__burger');

    if(burgerBtn) {
        const nav = document.querySelector('.header__nav-wrapper');
        const navLinks = document.querySelectorAll('.header__nav-link');
        const showPopupBtn = document.querySelector('.header__request');

        function hideNav() {
            burgerBtn.classList.remove('active');
            nav.classList.remove('show');
            document.body.classList.remove('no-scroll');
        }

        burgerBtn.addEventListener('click', ()=> {
            burgerBtn.classList.toggle('active');
            nav.classList.toggle('show');
            document.body.classList.toggle('no-scroll');
        });

        navLinks.forEach(el=> {
            el.addEventListener('click', ()=> {
                hideNav();
            });
        });

        showPopupBtn.addEventListener('click', ()=> {
            hideNav();
        });
    }
}

export default nav;