function tabs () {
/*=======
    Faq tabs
    =======*/
    const faq = document.querySelector('.faq');

    if(faq) {
        const faqItems = document.querySelectorAll('.faq__item');
        const faqTitle = document.querySelectorAll('.faq__title');
        const allContent = document.querySelectorAll('.faq__content');

        faqTitle.forEach(el=> {
            el.addEventListener('click', function(e) {
                const targetParentItem = this.closest('.faq__item');
                const targetContent = targetParentItem.querySelector('.faq__content');
                const targetTxt = targetContent.querySelector('.faq__txt');

                if(!this.classList.contains('active')) {
                    //Close all tabs
                    faqItems.forEach(el=> {
                        el.classList.remove('active');
                    });
                    faqTitle.forEach(el=> {
                        el.classList.remove('active');
                    });
                    allContent.forEach(el=> {
                        el.classList.remove('opened');
                        el.style.height = `0px`;
                    });
                    
                    //Open target tab
                    targetParentItem.classList.add('active');
                    this.classList.add('active');
                    targetContent.classList.add('opened');
                } else {
                    targetParentItem.classList.remove('active');
                    this.classList.remove('active');
                    targetContent.classList.remove('opened');
                }

                if(targetContent.classList.contains('opened')) {
                    targetContent.style.height = `${targetTxt.offsetHeight}px`;
                } else {
                    targetContent.style.height = `0px`;
                }
            });
        });
    }
}

export default tabs;