function popup () {
    /*=======
    Lightbox
    ========*/
    let lightbox = new SimpleLightbox('.gallery a', {});


    /*=======
    Popup
    ========*/
    const popup = document.querySelector('.popup');

    if(popup) {
        const showPopupBtn = document.querySelectorAll('.show-popup');
        const closePopup = document.querySelector('.popup__close');

        showPopupBtn.forEach(el=> {
            el.addEventListener('click', (e)=> {
                e.preventDefault();
                popup.classList.add('show');
                document.body.style.overflow = 'hidden';
            });
        });

        function hidePopup () {
            popup.classList.remove('show');
            document.body.style.overflow = 'initial';
        }

        closePopup.addEventListener('click', ()=> {
            hidePopup();
        });

        document.addEventListener('click', (e)=> {
            if(e.target == popup) {
                hidePopup();
            }
        });
    }
}

export default popup;