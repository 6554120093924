function forms () {
    const formConsult = document.querySelectorAll('.contact-form'),
        popup = document.querySelector('.popup'),
        popupComplete = document.querySelector('.popup-result');

    const formInput = document.querySelectorAll('.contact-form__input');

    formInput.forEach(el=> {
        el.oninput = function() {
            const targetBtn = this.closest('.contact-form').querySelector('.contact-form__submit');
            const targetInputName = this.closest('.contact-form').querySelector('.contact-form__input.name');
            const targetInputTel = this.closest('.contact-form').querySelector('.contact-form__input.phone');

            this.value.length >= 2 ? this.classList.add('completed') : this.classList.remove('completed');

            if(targetInputName.classList.contains('completed') && targetInputTel.classList.contains('completed')) {
                targetBtn.removeAttribute('disabled');
                targetBtn.style.cursor = 'pointer';
            } else {
                targetBtn.setAttribute('disabled', true);
                targetBtn.style.cursor = 'no-drop';
            }
        };

        //For IE9- and IE8-
        el.onfocus = function() {
            const targetBtn = this.closest('.contact-form').querySelector('.contact-form__submit');
            const targetInputName = this.closest('.contact-form').querySelector('.contact-form__input.name');
            const targetInputTel = this.closest('.contact-form').querySelector('.contact-form__input.phone');

            this.value.length >= 2 ? this.classList.add('completed') : this.classList.remove('completed');

            if(targetInputName.classList.contains('completed') && targetInputTel.classList.contains('completed')) {
                targetBtn.removeAttribute('disabled');
                targetBtn.style.cursor = 'pointer';
            } else {
                targetBtn.setAttribute('disabled', true);
                targetBtn.style.cursor = 'no-drop';
            }
        };

        el.onpropertychange = function() {
            const targetBtn = this.closest('.contact-form').querySelector('.contact-form__submit');
            const targetInputName = this.closest('.contact-form').querySelector('.contact-form__input.name');
            const targetInputTel = this.closest('.contact-form').querySelector('.contact-form__input.phone');

            this.value.length >= 2 ? this.classList.add('completed') : this.classList.remove('completed');

            if(targetInputName.classList.contains('completed') && targetInputTel.classList.contains('completed')) {
                targetBtn.removeAttribute('disabled');
                targetBtn.style.cursor = 'pointer';
            } else {
                targetBtn.setAttribute('disabled', true);
                targetBtn.style.cursor = 'no-drop';
            }
        }
    });



    formConsult.forEach(item => {
        postData(item);
    });

    function postData(formConsult) {
        formConsult.addEventListener('submit', (e)=> {
            e.preventDefault();
            
            const request = new XMLHttpRequest();
            request.open('POST', '/');

            const formData = new FormData(formConsult);

            request.send(formData);

            request.addEventListener('load', ()=> {
                if(request.status === 200) {
                    formConsult.reset();

                    if(popup.classList.contains('show')) {
                        setTimeout(()=> {
                            popup.classList.remove('show');
                            document.body.style.overflow = 'initial';
                        }, 300);
                    }

                    setTimeout(()=> {
                        popupComplete.classList.add('show');

                        setTimeout(()=> {
                            popupComplete.classList.remove('show');
                        }, 4500);
                    }, 500);
                } else {
                    alert('Что-то пошло не так, попробуйте снова или проверьте подключение к интернету');
                }
            });
        });  
    }
}

export default forms;