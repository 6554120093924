import slider from "./modules/slider";
import popup from "./modules/popup";
import tabs from "./modules/tabs";
import anchors from "./modules/anchors";
import nav from "./modules/nav";
import forms from "./modules/forms";

document.addEventListener('DOMContentLoaded', ()=> {
    slider();
    popup();
    tabs();
    anchors();
    nav();
    forms();
});
