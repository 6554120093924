function slider () {
    /*=======
    Documents slider
    =======*/
    const sliderDocuments = document.querySelector('.slider-documents');

    if(sliderDocuments) {
        let slider = new Swiper(sliderDocuments, {
            grabCursor: true,
            freeMode: {
                enabled: true,
            },
            slidesPerView: "auto",
            pagination: {
                el: ".slider-documents__pagination",
                clickable: true,
                momentumVelocityRatio: 0.2,
            },
            breakpoints: {
                1: {
                    spaceBetween: 10
                },
                768: {
                  spaceBetween: 20
                },
                1261: {
                  spaceBetween: 30
                },
            },
        });
    }


    /*=======
    Reviews slider
    =======*/
    const sliderReviews = document.querySelector('.reviews__slider');

    if(sliderReviews) {
        let slider = new Swiper(sliderReviews, {
            grabCursor: true,
            freeMode: {
                enabled: true,
                momentumVelocityRatio: 0.2,
            },
            navigation: {
                nextEl: ".reviews__slider-btn--next",
                prevEl: ".reviews__slider-btn--prev",
            },
            slidesPerView: "auto",
            breakpoints: {
                1: {
                    spaceBetween: 10
                },
                768: {
                  spaceBetween: 20
                },
                1261: {
                  spaceBetween: 30
                },
            },
        });


        //Removing extra characters from sliders txt
        const reviewsTxt = document.querySelectorAll('.reviews__slider-txt');

        reviewsTxt.forEach(title => {
            const text = title.textContent;
            
            if (text.length > 100) {
                const truncatedText = text.substring(0, 100) + '...';
                title.textContent = truncatedText;
            }
        });
    }
}

export default slider;